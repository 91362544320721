<template>
  <div id="second-level-support">
    <b-navbar toggleable="lg" type="light" variant="light">
      <!-- Logo -->
      <router-link :to="{ name: 'HomeSecondLevel' }" class="navbar-brand text-muted">
        <img alt="UPL Logo" src="../assets/HOPE-PS-Bank-Logo.png" style="height: 50px" height="50" width="66.66" />
        &nbsp;&nbsp; Second Level
      </router-link>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <!-- <li class="nav-item">
            <router-link :to="{ name: 'HomeSecondLevel' }" class="nav-link">
              <b-icon-house></b-icon-house>
              Home
            </router-link>
          </li> -->

          <li class="nav-item">
            <router-link :to="{ name: 'TicketsSecondLevel', params: { status: 'assigned' } }" class="nav-link">
              <b-icon-circle-fill class="text-primary"></b-icon-circle-fill>
              Assigned
            </router-link>
          </li>

          <li class="nav-item">
            <router-link :to="{ name: 'TicketsSecondLevel', params: { status: 'treated' } }" class="nav-link">
              <b-icon-circle-fill class="text-secondary"></b-icon-circle-fill>
              Treated
            </router-link>
          </li>

          <li class="nav-item">
            <router-link :to="{ name: 'TicketsSecondLevel', params: { status: 'resolved' } }" class="nav-link">
              <b-icon-circle-fill class="text-success"></b-icon-circle-fill>
              Resolved
            </router-link>
          </li>

          <b-nav-item-dropdown>
            <template #button-content>
              <!-- <b-icon-circle-fill class="text-warning"></b-icon-circle-fill> -->
              ...
            </template>
            <li>
              <router-link :to="{ name: 'TicketsSecondLevel', params: { status: 'escalated-l2-s1' } }" class="dropdown-item"><b-icon-circle-fill class="text-warning"></b-icon-circle-fill> Escalated L2-S1</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'TicketsSecondLevel', params: { status: 'escalated-l2-s2' } }" class="dropdown-item"><b-icon-circle-fill class="text-warning"></b-icon-circle-fill> Escalated L2-S2</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'TicketsSecondLevel', params: { status: 'breached' } }" class="dropdown-item"><b-icon-circle-fill class="text-dark"></b-icon-circle-fill> Breached</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'TicketsSecondLevel', params: { status: 'assigned-to-me' } }" class="dropdown-item"><b-icon-circle-fill class="text-purple"></b-icon-circle-fill> Assigned to me</router-link>
            </li>
          </b-nav-item-dropdown>

          <!-- Reports -->
          <b-nav-item-dropdown v-if="authUser.Role == 'second-level-support-supervisor-1' || authUser.Role == 'second-level-support-supervisor-2'">
            <template #button-content>
              <b-icon-bar-chart class="text-mute"></b-icon-bar-chart>
              Reports
            </template>

            <li>
              <router-link :to="{ name: 'SLABreached_SL' }" class="dropdown-item"> <b-icon-arrow-right-short></b-icon-arrow-right-short> SLA Breached</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'SLACompliance_SL' }" class="dropdown-item"><b-icon-arrow-right-short></b-icon-arrow-right-short> SLA Compliance</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Top10Cases_SL' }" class="dropdown-item"> <b-icon-arrow-right-short></b-icon-arrow-right-short> Top 10 Cases</router-link>
            </li>
          </b-nav-item-dropdown>
          <!-- End Reports -->
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <template #button-content> <b-icon-person-circle></b-icon-person-circle> {{ authUser.Name | shortenStr(25) }} </template>
            <li>
              <a href="https://support.up-ng.com/first-level-support" class="dropdown-item">
                <b-icon-link></b-icon-link>
                UP Portal
              </a>
            </li>
            <li>
              <a href="https://support.payattitude.com/first-level-support" class="dropdown-item">
                <b-icon-link></b-icon-link>
                Payattitude Portal
              </a>
            </li>
            <div class="dropdown-divider"></div>
            <li>
              <router-link :to="{ name: 'ProfileSecondLevel' }" class="dropdown-item">
                <b-icon-person></b-icon-person>
                Profile
              </router-link>
            </li>
            <Logout kind="list" />
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <router-view />
  </div>
</template>

<script>
import Logout from '@/components/Logout.vue'

export default {
  name: 'SecondLevelSupport',

  components: {
    Logout
  },

  computed: {
    authUser() {
      return this.$store.state.authUser
    },

    year() {
      let d = new Date()
      return d.getFullYear()
    }
  }
}
</script>
